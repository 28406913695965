<template>
  <div class="home">
    <div class="headerBox">
      <div class="leftBox">
        <div class="logo"></div>
        <div class="appInfo">
          <div class="title">春香阁APP下载导航</div>
          <div class="desc">永久网址：{{ url }}</div>
        </div>
      </div>
      <div class="rightBox" @click="jumpUrl('https://hls985.com')">
        <div class="icon"></div>
        <div class="name">每日吃瓜</div>
      </div>
    </div>
    <van-notice-bar color="white" :text="notice">
      <template slot="left-icon">
        <div class="noticeIcon"></div>
      </template>
    </van-notice-bar>
    <van-tabs class="myTabs" background="#09050a" swipeable :lazy-render="true">
      <van-tab :title="item.name" v-for="item in tabList" :key="item.id">
        <TabItem :moduleId="item.id" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { getTabs, visitRecord } from "@/api/home";
import TabItem from "./components/tabItem.vue";

export default {
  name: "home",
  components: {
    TabItem,
  },
  data() {
    return {
      tabList: [],
      url: "",
      emUrl: "",
      notice: "",
    };
  },
  mounted() {
    this.getTabs();
    setTimeout(() => {
      visitRecord();
    }, 5000);
  },
  methods: {
    async getTabs() {
      let res = await this.$Api(getTabs);
      if (res && res.code == 200) {
        this.tabList = res.data.module || [];
        this.url = res.data.url;
        this.emUrl = res.data.emUrl;
        this.notice = res.data.notice;
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  /deep/ .van-tabs {
    .van-tabs__wrap {
      height: 50px;
      .van-tab {
        font-size: 16px;
        color: #646566;
      }
      .van-tab--active {
        color: white;
      }
      .van-tabs__line {
        height: 8px;
        width: 22px;
      }
      .van-tabs__nav--line {
        padding-bottom: 10px;
      }
    }
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #ff8a48 0%, #ff3d57 100%);
    height: 55px;
    padding: 0 14px;
    .leftBox {
      display: flex;
      align-items: center;
      .logo {
        width: 40px;
        height: 40px;
        background: url("./../../assets/png/logo.png") no-repeat;
        background-size: 100% 100%;
      }
      .appInfo {
        margin-left: 5px;
        .title {
          font-size: 14px;
          font-weight: 800;
        }
        .desc {
          font-size: 12px;
        }
      }
    }
    .rightBox {
      display: flex;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        background: url("./../../assets/png/navIcon.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 3px;
      }
      .name {
        font-size: 13px;
      }
    }
  }
  .noticeIcon {
    height: 20px;
    width: 20px;
    background: url("./../../assets/png/noticeIcon.png") no-repeat;
    background-size: 100% 100%;
  }
  /deep/ .van-notice-bar {
    font-size: 12px !important;
    background-image: linear-gradient(90deg, #5e010f 0%, #6e595c 100%);
    height: 30px;
  }
}
</style>
